import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto login-page" }
const _hoisted_2 = { class: "fv-row mb-10" }
const _hoisted_3 = { class: "fv-row mb-10" }
const _hoisted_4 = {
  key: 0,
  class: "fv-row mb-5"
}
const _hoisted_5 = { class: "d-flex flex-stack-mb-2" }
const _hoisted_6 = { class: "text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_modal_password_reset = _resolveComponent("vc-modal-password-reset")!
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vc_modal_password_reset, { ref: "resetPassword" }, null, 512),
    _createVNode(_component_el_form, {
      ref: "formSignIn",
      model: _ctx.constant.signin,
      "status-icon": "",
      rules: _ctx.rule.signin,
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.auth.signin(_ctx.formSignIn)), ["prevent"]))
    }, {
      default: _withCtx(() => [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-center mb-10" }, [
          _createElementVNode("h1", { class: "text-dark mb-3" }, "Sign In to Health Skye")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_vc_input, {
            label: "Username",
            prop: "email",
            size: "large",
            modelValue: _ctx.constant.signin.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.constant.signin.email) = $event)),
            isPrefix: "",
            keydown: "return /[-a-z._@0-9]/i.test(event.key)"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_vc_input, {
            label: "Password",
            prop: "password",
            isInput: "password",
            size: "large",
            modelValue: _ctx.constant.signin.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.constant.signin.password) = $event)),
            isAppend: "",
            isPrefix: ""
          }, null, 8, ["modelValue"])
        ]),
        (_ctx.constant.signin.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.constant.signin.error), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_vc_button_save, {
          loading: _ctx.loading.signin,
          disabled: _ctx.disable.signin,
          className: "btn btn-lg btn-info w-100 h-auto mb-5",
          text: "Continue"
        }, null, 8, ["loading", "disabled"])
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_el_button, {
      text: "",
      onClick: _ctx.resetPass
    }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [
        _createElementVNode("span", { style: {"color":"#409eff"} }, " Reset Password ", -1)
      ])),
      _: 1
    }, 8, ["onClick"])
  ]))
}